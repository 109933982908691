import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import Layout from "../components/layout";
import Helmet from "react-helmet";

const useStyles = makeStyles((theme) => ({
  message: {
    fontWeight: 600,
    lineHeight: 3,
    height: 200,

    fontSize: "4.44vw",
    "@media(min-width: 1440px)": {
      fontSize: "64px",
    },
    "@media(max-width: 414px)": {
      fontSize: "8.69vw",
    },
  },
}));

const DataSend = () => {
  const classes = useStyles();

  const userData =
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem("user_data"))
      : null
      
  if (typeof localStorage !== "undefined") localStorage.removeItem("user_data")

  // let userData = null;
  // React.useEffect(() => {
  //   userData = JSON.parse(window.localStorage.getItem("user_data"));
  //   // window.localStorage.removeItem("user_data");
  // }, []);
  // const userData =
  //   typeof localStorage !== "undefined"
  //     ? JSON.parse(localStorage.getItem("user_data"))
  //     : null
      
  // if (typeof localStorage !== "undefined") localStorage.removeItem("user_data")

  // let userData = JSON.parse(window.localStorage.getItem("user_data"));

  const initComand = () => `
    ;(function (_, r, e, t, a, i, l) {
      _["retailCRMObject"] = a
      _[a] =
        _[a] ||
        function () {
          ;(_[a].q = _[a].q || []).push(arguments)
        }
      _[a].l = 1 * new Date()
      l = r.getElementsByTagName(e)[0]
      i = r.createElement(e)
      i.async = !0
      i.src = t
      l.parentNode.insertBefore(i, l)
    })(window, document, "script", "https://collector.retailcrm.pro/w.js", "_rc")
    
    _rc("create", "RC-17731690945-2")
    
    _rc("send", "pageView")
  `;
  const sendComand = () => `
    _rc('send', 'order', {
      orderMethod: 'new-diller',
      name: '${userData.name}',
      phone: '${userData.phone}',
      email: '${userData.email}',
      customDirection: '${userData.customDirection}',
      
      callback: function (success, response) {
        if (success) {
          console.log(response)
          localStorage.removeItem("user_data")
        } else {
          console.log(response)
          window.open('/error-page')
        }
      },
    })
  `;


  return userData ? (
    <>
      <Helmet>
        <script type="text/javascript">{initComand()}</script>
      </Helmet>

      <Helmet>
        <script type="text/javascript">{sendComand()}</script>
      </Helmet>
      <Layout>
        <Typography align="center" className={classes.message}>
          Данные отправлены 
        </Typography>
      </Layout>
    </>
  ) : null
};

export default DataSend;
